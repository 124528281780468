var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "add-new-investor-form"
  }, [_c('sygni-loader', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }), _c('div', {
    staticClass: "content"
  }, [_c('sygni-container-title', {
    staticClass: "mb-3"
  }, [_c('span', [_vm._v("Add Fund")])]), _c('div', {
    staticClass: "content__title"
  }, [_vm._v(" General data ")]), _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'NAME',
      "validation": _vm.$v.newFund.name
    },
    model: {
      value: _vm.newFund.name,
      callback: function callback($$v) {
        _vm.$set(_vm.newFund, "name", $$v);
      },
      expression: "newFund.name"
    }
  }), _c('sygni-input', {
    ref: "taxNumberInput",
    staticClass: "secondary",
    attrs: {
      "label": 'TAX NUMBER'
    },
    on: {
      "blur": _vm.loadDataFromNip
    },
    model: {
      value: _vm.newFund.taxNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.newFund, "taxNumber", $$v);
      },
      expression: "newFund.taxNumber"
    }
  }), _vm.hasLegalEntityAlready ? _c('sygni-info-box', {
    staticClass: "mb-4"
  }, [_vm.isLegalEntityAlreadyUsed ? [_vm._v(" Fund with entered tax number is already assigned to your account. "), _c('sygni-link-button', {
    staticClass: "gn-secondary",
    attrs: {
      "type": "simple"
    },
    on: {
      "click": _vm.goToSelectedLegalEntity
    }
  }, [_vm._v("Go to Fund")])] : [_vm._v(" Fund with entered tax number already exists in our platform. "), _c('sygni-link-button', {
    staticClass: "gn-secondary",
    attrs: {
      "type": "simple"
    },
    on: {
      "click": _vm.joinSelectedLegalEntity
    }
  }, [_vm._v("Request to join it")])]], 2) : _vm._e(), _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'REGISTER NUMBER'
    },
    on: {
      "blur": _vm.loadDataFromKrs
    },
    model: {
      value: _vm.newFund.registerNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.newFund, "registerNumber", $$v);
      },
      expression: "newFund.registerNumber"
    }
  }), _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'STATISTICAL NUMBER'
    },
    model: {
      value: _vm.newFund.statisticalNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.newFund, "statisticalNumber", $$v);
      },
      expression: "newFund.statisticalNumber"
    }
  }), _c('sygni-select', {
    staticClass: "secondary",
    attrs: {
      "options": _vm.legalEntitySelects.programme,
      "label": 'PROGRAMME',
      "placeholder": 'Choose',
      "validation": _vm.$v.newFund.programme
    },
    model: {
      value: _vm.newFund.programme,
      callback: function callback($$v) {
        _vm.$set(_vm.newFund, "programme", $$v);
      },
      expression: "newFund.programme"
    }
  }), _c('sygni-select', {
    staticClass: "secondary",
    attrs: {
      "options": _vm.legalEntitySelects.legalForm,
      "label": 'LEGAL FORM',
      "validation": _vm.$v.newFund.legalForm,
      "placeholder": 'Choose'
    },
    model: {
      value: _vm.newFund.legalForm,
      callback: function callback($$v) {
        _vm.$set(_vm.newFund, "legalForm", $$v);
      },
      expression: "newFund.legalForm"
    }
  }), _c('div', {
    staticClass: "content__title"
  }, [_vm._v(" Additional information ")]), _c('logo-uploader', {
    on: {
      "input": function input($event) {
        _vm.logo = $event;
      }
    }
  }), _c('sygni-text-area', {
    staticClass: "secondary",
    attrs: {
      "label": 'DESCRIPTION'
    },
    model: {
      value: _vm.newFund.description,
      callback: function callback($$v) {
        _vm.$set(_vm.newFund, "description", $$v);
      },
      expression: "newFund.description"
    }
  }), _c('sygni-date-picker', {
    staticClass: "secondary",
    attrs: {
      "label": 'ESTABLISHMENT DATE'
    },
    model: {
      value: _vm.newFund.establishmentDate,
      callback: function callback($$v) {
        _vm.$set(_vm.newFund, "establishmentDate", $$v);
      },
      expression: "newFund.establishmentDate"
    }
  }), _c('sygni-select', {
    staticClass: "secondary",
    attrs: {
      "options": _vm.legalEntitySelects.endOfFinancialPeriod,
      "label": 'END OF FINANCIAL YEAR',
      "placeholder": 'Choose',
      "validation": _vm.$v.newFund.endOfFinancialPeriod
    },
    model: {
      value: _vm.newFund.endOfFinancialPeriod,
      callback: function callback($$v) {
        _vm.$set(_vm.newFund, "endOfFinancialPeriod", $$v);
      },
      expression: "newFund.endOfFinancialPeriod"
    }
  }), _c('div', {
    staticClass: "content__title"
  }, [_vm._v(" Registration address ")]), _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'STREET',
      "validation": _vm.$v.newFund.registrationAddress.street
    },
    model: {
      value: _vm.newFund.registrationAddress.street,
      callback: function callback($$v) {
        _vm.$set(_vm.newFund.registrationAddress, "street", $$v);
      },
      expression: "newFund.registrationAddress.street"
    }
  }), _c('div', {
    staticClass: "d-flex flex-row"
  }, [_c('sygni-input', {
    staticClass: "secondary mr-1 w-75",
    attrs: {
      "label": 'BUILDING NUMBER',
      "validation": _vm.$v.newFund.registrationAddress.houseNumber
    },
    model: {
      value: _vm.newFund.registrationAddress.houseNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.newFund.registrationAddress, "houseNumber", $$v);
      },
      expression: "newFund.registrationAddress.houseNumber"
    }
  }), _c('sygni-input', {
    staticClass: "secondary ml-1 w-50",
    attrs: {
      "label": 'APARTMENT NUMBER',
      "validation": _vm.$v.newFund.registrationAddress.flatNumber
    },
    model: {
      value: _vm.newFund.registrationAddress.flatNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.newFund.registrationAddress, "flatNumber", $$v);
      },
      expression: "newFund.registrationAddress.flatNumber"
    }
  })], 1), _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'CITY',
      "validation": _vm.$v.newFund.registrationAddress.city
    },
    model: {
      value: _vm.newFund.registrationAddress.city,
      callback: function callback($$v) {
        _vm.$set(_vm.newFund.registrationAddress, "city", $$v);
      },
      expression: "newFund.registrationAddress.city"
    }
  }), _c('sygni-select', {
    staticClass: "secondary",
    attrs: {
      "label": 'COUNTRY',
      "searchable": true,
      "validation": _vm.$v.newFund.registrationAddress.country,
      "options": _vm.globalSelects.countries
    },
    model: {
      value: _vm.newFund.registrationAddress.country,
      callback: function callback($$v) {
        _vm.$set(_vm.newFund.registrationAddress, "country", $$v);
      },
      expression: "newFund.registrationAddress.country"
    }
  }), _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'ZIP CODE',
      "validation": _vm.$v.newFund.registrationAddress.zipCode
    },
    model: {
      value: _vm.newFund.registrationAddress.zipCode,
      callback: function callback($$v) {
        _vm.$set(_vm.newFund.registrationAddress, "zipCode", $$v);
      },
      expression: "newFund.registrationAddress.zipCode"
    }
  }), _c('div', {
    staticClass: "content__title"
  }, [_vm._v(" Your role in Fund ")]), _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'POSITION',
      "validation": _vm.$v.newFund.employee.position
    },
    model: {
      value: _vm.newFund.employee.position,
      callback: function callback($$v) {
        _vm.$set(_vm.newFund.employee, "position", $$v);
      },
      expression: "newFund.employee.position"
    }
  }), _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'DESCRIPTION'
    },
    model: {
      value: _vm.newFund.employee.description,
      callback: function callback($$v) {
        _vm.$set(_vm.newFund.employee, "description", $$v);
      },
      expression: "newFund.employee.description"
    }
  }), _c('sygni-checkbox', {
    staticClass: "secondary",
    model: {
      value: _vm.newFund.employee.managementTeam,
      callback: function callback($$v) {
        _vm.$set(_vm.newFund.employee, "managementTeam", $$v);
      },
      expression: "newFund.employee.managementTeam"
    }
  }, [_vm._v("MANAGEMENT TEAM")]), _c('div', {
    staticClass: "description"
  }, [_vm._v(" Please check that every requested information are correct. Any special information can be included here. ")]), _c('sygni-rounded-button', {
    staticClass: "filled gn-primary button secondary",
    attrs: {
      "loading": _vm.isLoading,
      "arrow-icon": true
    },
    on: {
      "click": function click($event) {
        return _vm.handleAddFundAction();
      }
    }
  }, [_vm._v("Add fund ")]), _c('router-link', {
    attrs: {
      "to": _vm.routerLink
    }
  }, [_c('div', {
    staticClass: "cancel-button"
  }, [_vm._v("Cancel")])])], 1), _c('sygni-modal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showConfirmationModal,
      expression: "showConfirmationModal"
    }],
    attrs: {
      "isLoading": _vm.isLoading,
      "header": "Fund already exists",
      "cancelText": "Close",
      "confirmText": ""
    },
    on: {
      "close": _vm.closeConfirmationModal,
      "cancel": _vm.closeConfirmationModal
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('p', {
          staticClass: "description text-center"
        }, [_vm._v(" Fund with entered tax number already exists in our platform. "), _vm.isLegalEntityAlreadyUsed ? _c('sygni-link-button', {
          staticClass: "gn-secondary",
          attrs: {
            "type": "simple"
          },
          on: {
            "click": _vm.goToSelectedLegalEntity
          }
        }, [_vm._v("Click here to go to existing fund")]) : _c('sygni-link-button', {
          staticClass: "gn-secondary",
          attrs: {
            "type": "simple"
          },
          on: {
            "click": _vm.joinSelectedLegalEntity
          }
        }, [_vm._v("Click here to request to join existing fund")])], 1)];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }